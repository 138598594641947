import Vue from "vue";
import Vuex from "vuex";
import router from "../router";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    base_url: "https://api.cjsmp.com/",
    token: localStorage.getItem("token") || null,
    user_name: localStorage.getItem("user_name") || null,
    email: localStorage.getItem("email") || null,
    phone: localStorage.getItem("phone") || null,
    photo: localStorage.getItem("photo") || null,
    onboard: localStorage.getItem("onboard") || null,
    onboardData: [],
  },
  getters: {
    loggedIn(state) {
      return state.token !== null;
    },

    onboardData(state) {
      return state.onboardData;
    },
  },
  mutations: {
    setToken(state, data) {
      state.token = data.token;
      state.user_name = data.name;
      state.email = data.email;
      state.phone = data.phone;
      state.photo = data.photo;
      state.onboard = data.onboard_status;
    },
    removeToken(state) {
      state.token = null;
      state.user_name = null;
      state.email = null;
      state.phone = null;
      state.photo = null;
      state.onboard = null;
    },
    goToOnboard(state) {
      state.onboard == 0
        ? router.push("/onboard")
        : localStorage.getItem("onboard");
    },
    onboardData(state, data) {
      state.onboardData = data;
    },
  },
  actions: {
    removeToken(context) {
      context.commit("removeToken");
    },
    setToken(context, data) {
      context.commit("setToken", data);
    },

    CheckAuth(content) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("token");
      axios
        .get("/candidate/check")
        .then((res) => {
          if (res.data.status == 3) {
            localStorage.removeItem("token");
            content.commit("removeToken");
            router.push("/login");
          } else if (res.data.status == 2) {
            router.push("/verify");
          } else if (res.data.status == 1) {
            content.commit("goToOnboard");
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            localStorage.removeItem("token");
            content.commit("removeToken");
            router.push("/login");
          }
        });
    },
    onboardData(context) {
      axios
        .get("/candidate/onboard")
        .then((res) => {
          if (res.data) {
            context.commit("onboardData", res.data);
          }
        })
        .catch((e) => {
          this.e(e.response.data.error);
        });
    },
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
});
